import Layout from '@components/layout/layout-six'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import ErrorInformation from '@components/404/error-information'
import Seo from '@components/seo/seo'
import { useTranslation } from 'next-i18next'

export default function ErrorPage() {
  const { t: translate } = useTranslation('common')

  return (
    <>
      <Seo
        title="404"
        description={translate('text-home-seo-blurb', { ns: 'common' })}
        path="404"
      />
      <ErrorInformation />
    </>
  )
}

ErrorPage.Layout = Layout

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, [
        'common',
        'categories',
        'forms',
        'menu',
        'footer',
      ])),
    },
  }
}
